import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apolloClient } from "../..";
import { PROPERTY_QUERY } from "../graphql";
import { IProperty } from "../../types/Property";


export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

export interface IPropertySliceState {
  property: IProperty;
  status: string;
  updated: boolean;
  loanUpdated: number;
  expenseUpdated: number;
}

const propertySlice = createSlice({

  name: "property",
  initialState: {
    property: {},
    status: STATUSES.IDLE,
    updated: false,
    loanUpdated: 0,
    expenseUpdated: 0,
  } as IPropertySliceState,
  reducers: {
    add: (state, action) => {
      state.property = action.payload;
    },
    updateLoan: (state, action) => {
      state.updated = true;
      state.loanUpdated = action.payload.loanId;
      for(var i = 0; i < state.property.loans.length; i++) {
        if( state.property.loans[i].loanId === action.payload.loanId ) {
          state.property.loans[i] = {
            ...state.property.loans[i],
            [action.payload.key]: action.payload.value
          }
        }
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(FetchProperty.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(FetchProperty.fulfilled, (state, action) => {
        state.property = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(FetchProperty.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { add, updateLoan } = propertySlice.actions;
export default propertySlice.reducer;

export const getLoans = (state: { property: any; }) => state.property.property.loans;
export const getLoan = (state: { property: IPropertySliceState}, loanId: number) => {
  console.log("GetLoan: ", state.property.property.loans, " LoanID: ", loanId)
  return state.property.property.loans.find((loan: { loanId: number; }) => loan.loanId===loanId);
}

export const FetchProperty = createAsyncThunk("property/fetch", async (propertyId:number, { getState }) => {
  const state = getState();
  console.log("FetchProperty: ", state)
  // if( state && state.property && state.property.propertyId === propertyId ) return state.property;

  const response = await apolloClient.query({
    query: PROPERTY_QUERY,
    variables: {
      propertyId: propertyId
    }
  });
  return response.data.property;
});