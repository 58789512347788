import React, { useState } from "react";
import { currencyFormat, numberFormat, percentFormat } from "./Formats";
import '../styles/Editable.css';

const Editable = (props: IEditableParms) => {
  const { text="", id="", type, onEnter } = props;

  // Manage the state whether to show the label or the input box. By default, label will be shown.
  // Exercise: It can be made dynamic by accepting initial state as props outside the component 
  const [isEditing, setEditing] = useState(false);
  const [value, setValue] = useState(text);

  const setDefault = () => {
    setValue(text);
    setEditing(false);
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement|HTMLTextAreaElement>  = (event) => {
    const { key } = event;
    const keys = ["Escape", "Tab"];
    const enterKey = "Enter";

    if( keys.indexOf(key) >= 0 ) {
      if(key==="Escape") setDefault();
      event.currentTarget.blur();
    }
    // const input = event.target as HTMLInputElement;
    // console.log("Got a key down ",  key, event.target, value)
    if( type==="TextArea" && key === enterKey && !event.ctrlKey ) return;

    if( key === enterKey ) {
      if( value.trim() === "") {
        setDefault();
      } else {
        setEditing(false);
        onEnter(id, value);
      }
    }
  };

  const valWithFormat = (val:string) => {
    switch(type) {
      case "Percent":
        return percentFormat(Number(val)) 
      case "Number":
        return numberFormat(Number(val))
      case "Currency":
        return currencyFormat(Number(val)) 
      default:
        return val;
    }

  }

  const valColor = () => {
    if( type==="String" || type==="Text" || "TextArea" ) return 'black';
    if( Number(value) < 0 ) return 'red';
    if( Number(value) >= 0 ) return 'green';
  }

  const textAreaSize = (value:any) => {
    let taArr = value.split(/\n/);
    let rows = taArr.length + 1;
    let cols = 100;
    for(let i=0; i<taArr.length; i++) {
      cols = taArr[i].length > cols ? cols=taArr[i].length + 1 : cols;
    }
    return {rows: rows, cols: cols};
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement|HTMLTextAreaElement> = (event) => {
    setValue(event.target.value);
    props.handleChange && props.handleChange(event.target.id, event.target.value)
  }

  /*
    Single element which is good for tabbing, etc. But cannot be styled (that I know of)
    with currencyFormat component
  */

  let myStyle;
  if(props.bold) myStyle={fontWeight:'bold'}

  if( type === "TextArea" ) {
   let taSize = textAreaSize(value);
    return (
      <textarea
        cols={taSize.cols}
        rows={taSize.rows}
        className={`input ${valColor()}`}
        value={isEditing ? value : valWithFormat( value ) }
        onFocus={() => setEditing(true)}
        onBlur={() => setEditing(false)}
        onChange={handleChange}
        onKeyDown={e => handleKeyDown(e)}
        readOnly={!isEditing}
        id={id}
      />
    )
  } else {
    return (
      <input
        type={type}
        style={myStyle}
        className={`input ${valColor()}`}
        value={isEditing ? value : valWithFormat( value ) }
        onFocus={() => setEditing(true)}
        onBlur={() => setEditing(false)}
        onChange={handleChange}
        onKeyDown={e => handleKeyDown(e)}
        placeholder={props.placeHolder || ""}
        readOnly={!isEditing}
        id={id}
      />
    )
  }

  /*
  return (
    <section>
      {isEditing ? (
        <div
          className="input"
          onKeyDown={e => handleKeyDown(e)}
          onBlur={()=>{setEditing(false)}}
        >
          <input type="text" name="task" value={value} onChange={e => setValue(e.target.value)} />
        </div>
      ) : (
        <div
          onClick={() => setEditing(true)}
          className="input"
          tabIndex={1}
        >
          {currencyColored(Number(value))}
        </div>
      )}
    </section>
  );
  */

};

interface IEditableParms {
  text?: string;
  id?: string;
  placeHolder?: string;
  handleChange?: (id: string, text: string) => void;
  onEnter: (id: string, text: string) => void;
  type?: string;
  bold?: boolean;
}

Editable.defaultProps = {
  type: "Currency"
};

export default Editable;