import React, { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { METRO_QUERY } from "../platform/graphql";
import { Table } from "react-bootstrap";
import { TextField } from "./Form";
import { IMetro, IMetros } from "../types/Metro";
import MetroList from "./MetroList";

const Metro = (props: IMetroParams) => {
  const [zipCode, setZipCode] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [metros, setMetros] = useState<IMetros>();

  const client = useApolloClient();

  useEffect(() => {
    if( zipCode.length !== 5 ) {
      return;
    }
    client.query({
      query: METRO_QUERY,
      variables: {
        zipCode: Number(zipCode)
      }
    })
    .then((data) => {
      //Only one metro for zip code (if found)
      props.onMetro(data.data.metros[0]);
    })
    .catch((error) => console.log(error));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[zipCode])

  useEffect(() => {
    if( name.length  <= 3 ) {
      return;
    }
    client.query({
      query: METRO_QUERY,
      variables: {
        name: name
      }
    })
    .then((data) => {
      console.log(data)
      if( data.data.metros.length === 1 ) {
        props.onMetro(data.data.metros[0]);
      } else {
        setMetros(data.data);
      }
    })
    .catch((error) => console.log(error));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[name])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    console.log("Handled change: ", event.currentTarget.value, event.currentTarget.id)
    switch(event.currentTarget.id) {
      case "zipCode":
        setZipCode( event.currentTarget.value );
        break;
      case "name":
        setName( event.currentTarget.value )
        break;
    };
  }

  return (
    <React.Fragment>
       <h2>Metro</h2>
      <div className="form-group">
        <Table bordered size="sm">
        <tbody>
          <tr>
            <td>Zip Code:</td>
            <td><TextField id="zipCode" placeHolder="Zip Code (Postal Code)" handleChange={handleChange} /></td>
          </tr>
          <tr>
            <td>Metro Name:</td>
            <td><TextField id="name" placeHolder="" handleChange={handleChange} /></td>
          </tr>
          <MetroList metros={metros} onMetro={props.onMetro}/>
        </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
}

interface IMetroParams {
  onMetro: (metro: IMetro) => void;
}

export default Metro;