// 
// Put number formations, etc, etc.
// 

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF'.split('');
  var color = '#';
  for (var i = 0; i < 6; i++ ) {
      color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export interface IHash {
  [key: string] : string;
};
export const months = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
} as IHash;

export const monthYearFromMonth = (month: number=0) => {
  if( month===0 ) return;
  let mString = month.toString();
  let ret = months[ mString.substring(4,6) ] + ' ' + mString.substring(2,4)
  return ret
}

export const numberFormat = (value: number=0) =>
  new Intl.NumberFormat('en-US', {
  }).format(value);

export const percentFormat = (value: number=0) =>
  new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumSignificantDigits: 3
  }).format(value);

export const currencyFormat = (value: number=0) => {
  return (
    value % 1 === 0 ? currencyWithCents(value,0) : currencyWithCents(value,2)
  )
}
export const currencyWithCents = (value:number=0, digits:number=2) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    maximumFractionDigits: digits,
    currency: 'USD'
  }).format(value||0);

export const currencyColored = (value:number=0) => {
  const color = value < 0 ? 'red' : 'green';
  return (
    <div style={{color: color}}>
      {currencyFormat(value)}
    </div>
  )
}
