import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import AmortizationTable from "./AmortizationTable";
import { HideOrShow, TextField } from "./Form";
import { currencyFormat } from "./Formats";

export default function Loan() {
  const [loan, setLoan] = useState<ILoanInt>({
    price: 100000,
    rate: 5.75,
    percentDown: 20,
    length: 30,
    downPayment: 20000,
  });
  const [mortgage, setMortgage] = useState<IMortgage>();

  useEffect(() => {
    let principle = loan.price - loan.downPayment;
    let interest = (loan.rate || 1) / 100 / 12;
    let term = loan.length * 12;
    let divisorPow = 1 - Math.pow(1 + interest, 0 - term);
    let monthly = (interest * principle) / divisorPow;

    let intPaid = 0;
    for(let i=0; i<term; i++) {
      let intAmt = (interest * principle);
      intPaid += intAmt;
      principle -= (monthly - intAmt);
    }
    
    setMortgage({
      principle: loan.price,
      term: term,
      monthly: monthly,
      divisorPow: divisorPow,
      interest: interest,
      totalPayout: loan.price + intPaid,
      totalInterest: intPaid,
    } as IMortgage);

  },[loan])


  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    // console.log("Handled change: ", event.currentTarget.value, event.currentTarget.id)
    const {id,value} = event.currentTarget;

    if( id==="percentDown" ) {
      setLoan({
        ...loan,
        [id]: Number(value),
        downPayment: loan.price * (Number(value) / 100)
      } as ILoanInt)
    } else if( id==="downPayment") {
      setLoan({
        ...loan,
        [id]: Number(value),
        percentDown: (Number(value) / loan.price) * 100
      } as ILoanInt)
    } else if( id==="price") {
      setLoan({
        ...loan,
        [id]: Number(value),
        downPayment: (Number(value) * (loan.percentDown || 20)) / 100
      } as ILoanInt)
    } else {
      setLoan({
        ...loan,
        [id]: Number(value),
      } as ILoanInt)

    }
  }

  return (
    <React.Fragment>
    <h2>Loan Information</h2>
      <div className="form-group">
        <Table bordered size="sm">
          <tbody>
            <tr>
              <td>Principle:</td>
              <td><TextField id="price" type="number" value={loan.price} handleChange={handleChange}/></td>
            </tr>
            <tr>
              <td>Down Payment:</td>
              <td><TextField id="downPayment" type="number" value={(loan.downPayment || 0).toString()} handleChange={handleChange}/></td>
            </tr>
            <tr>
              <td>Percent Down:</td>
              <td><TextField id="percentDown" type="number" value={(loan?.percentDown ||0).toString()} handleChange={handleChange}/></td>
            </tr>
            <tr>
              <td>Rate:</td>
              <td><TextField id="rate" type="number" value={(loan.rate||"").toString()} handleChange={handleChange}/></td>
            </tr>
            <tr>
              <td>Length:</td>
              <td><TextField id="length" type="number" value={loan.length} handleChange={handleChange}/></td>
            </tr>
            <tr><td colSpan={2}><br/></td></tr>
            <tr>
              <td>Principle:</td>
              <td>{currencyFormat(mortgage?.principle)}</td>
            </tr>
            <tr>
              <td>Monthly:</td>
              <td>
                {currencyFormat(mortgage?.monthly)}
                <span style={{paddingLeft: '50px'}}> 
                  Annual: {currencyFormat((mortgage?.monthly || 0) * 12)}
                </span>
              </td>
            </tr>
            <tr>
              <td>Total Payout:</td>
              <td>{currencyFormat(mortgage?.totalPayout)}</td>
            </tr>
            <tr>
              <td>Total Interest:</td>
              <td>{currencyFormat(mortgage?.totalInterest)}</td>
            </tr>
          </tbody> 
        </Table>
        <HideOrShow title="Amortization Table">
          <AmortizationTable mortgage={mortgage}/>
        </HideOrShow>
      </div>
    </React.Fragment>
  );
}

interface ILoanInt {
  price: number;
  percentDown?: number;
  rate?: number;
  length: number;
  downPayment: number;
}

export interface IMortgage {
  principle: number;
  term: number;
  monthly: number;
  divisorPow: number;
  interest: number;
  totalPayout: number;
  totalInterest: number;
}