import { Routes, Route, Link, Navigate } from 'react-router-dom'
import Properties from './components/properties/Properties';
import PropertyNew from './components/properties/PropertyNew';
import PropertyEdit from './components/properties/PropertyEdit';
import PropertyShow from './components/properties/PropertyShow';
import PropertyExpenses from './components/properties/PropertyExpenses';
import Loan from './components/Loan';
import List from './components/List';
import Account from './components/Account';
import ChartApartments from './components/charts/ChartApartments';
import Dictionary from './components/Dictionary';
import { TypesTabs } from './components/types/TypesTabs';
import { NotFoundPage } from './pages/NotFound';
import { Container, Navbar } from 'react-bootstrap';
import { platformConfig } from './platform/config/platform';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './App.css';
import React from 'react';

const ip = process.env.HTTP_HOST;
console.log(process.env)

function App() {
  return (
    <GoogleOAuthProvider clientId="911091827439-hmod1tltu8vuf4h0gvj6pjcl5s0o126t.apps.googleusercontent.com">
    <Container>
    <Navbar expand="lg" variant="light" bg="light">
      <Container>
        <Link className="nav-link" to="/charts/apartments">Home</Link>
        <Link className="nav-link" to="/loan">Loan Calc</Link>
        {
          platformConfig.production ? <></> :
          <>
            <Link className="nav-link" to="/properties">Properties</Link>
            <Link className="nav-link" to="/lists">Lists</Link>
            <Link className="nav-link" to="/dictionary">Dictionary</Link>
            <Link className="nav-link" to="/types">Types</Link>
            <Link className="nav-link" to="/account">Account</Link>
          </>
        }
      </Container>
    </Navbar><br />
  
    <Routes>
      <Route path="/" element={ <Navigate to="/charts/apartments" /> } />
      <Route path="/loan" element={<Loan />} />
      <Route path="/lists" element={<List />} />
      <Route path="/properties" element={<Properties />} />
      <Route path="/property/new" element={<PropertyNew />} />
      <Route path="/properties/:propertyId/edit" element={<PropertyEdit />} />
      <Route path="/properties/:propertyId" element={<PropertyShow />} />
      <Route path="/properties/:propertyId/expenses" element={<PropertyExpenses />} />
      <Route path="/dictionary" element={<Dictionary />} />
      <Route path="/types" element={<TypesTabs />} />
      <Route path="/account" element={<Account />} />
      <Route path="/charts/apartments" element={<ChartApartments />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </Container>
  </GoogleOAuthProvider>
  );
}

export default App;
