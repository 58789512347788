import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';


const Account = () => {

const [ user, setUser ] = useState([]);

const login = useGoogleLogin({
  onSuccess: (codeResponse) => console.log(codeResponse),
  onError: (error) => console.log('Login Failed:', error)
});

  return(
  // <GoogleLogin
  //     onSuccess={credentialResponse => {
  //       console.log(credentialResponse);
  //     }}
  //     onError={() => {
  //       console.log('Login Failed');
  //     }}
  //   />

  <button onClick={() => login()}>Sign in with Google </button>

  )
}
export default Account;