import { Link } from 'react-router-dom';
import { currencyFormat, currencyColored, numberFormat } from '../Formats';
import { PROPERTIES_QUERY } from '../../platform/graphql';
import { IProperty, IProperties} from '../../types/Property';
import { useQuery } from "@apollo/client";

function renderProperty(property: IProperty) {

  return <tr key={property.propertyId}>
      <td>
      <Link to={`/properties/${property.propertyId}`}>{property.type}</Link>
      </td>
      <td>
        <a target="_blank" rel="noreferrer" href={property.url} >
          {property.addr1}, {property.city}, {property.stateCode} {property.zipCode}
        </a>
      </td>
      <td>{currencyFormat(property.price)}</td>
      <td>{currencyColored(property.noi)}</td>
      <td>{currencyColored(property.cashFlow)}</td>
      <td>{numberFormat(property.grm)}</td>
  </tr>
}

export default function Properties() {
  const {loading, error, data} = useQuery<IProperties>(PROPERTIES_QUERY);
  if (loading) return <h2>Loading...</h2>
  if (error) return <pre>{error.message}</pre>

  return( 
       <>
        <h2>Properties</h2>
        <div><Link to="/property/new/">New Property</Link></div>
            <table className="table leader-table">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Address</th>
                        <th>Price</th>
                        <th>NOI</th>
                        <th>P&L</th>
                        <th>GRM</th>
                    </tr>
                </thead>
                <tbody>
                  {data && data.properties.map(property => renderProperty(property))}
                </tbody>
            </table>
    </>
  )
}