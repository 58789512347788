import React, { useState, useEffect } from "react";
import {Option, Dropdown,TextField, Frequency} from "../Form";
import { IProperty, IPropertyTypes } from '../../types/Property';
import { IPostalCode } from "../../types/PostalCode";
import { POSTAL_CODE_QUERY, PROPERTY_TYPES_QUERY, QUICK_PROPERTY } from "../../platform/graphql";
import { Button, Table } from "react-bootstrap";
import { useApolloClient } from "@apollo/client";
import { useNavigate } from "react-router-dom";

export const PropertyNew = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [propertyTypes, setPropertyTypes] = useState<IPropertyTypes>();
  const [zipCode, setZipCode] = useState<string>("");
  const [zipCodeData, setZipCodeData] = useState<IPostalCode>();
  const [property, setProperty] = useState<IProperty>();
  const [frequency, setFrequency] = useState<number>(1);

  const client = useApolloClient();
  const navigate = useNavigate()

  const blankZip = () => {
    setZipCodeData({
      postalCode:{ 
        city: "",
        stateCode: "",
      }
    } as IPostalCode);
  }

  const getZip = async () => {
    console.log("getZip ", zipCode)
    if( zipCode.length !== 5 ) {
      blankZip();
      return;
    }

    const response = await client.query({
      query: POSTAL_CODE_QUERY,
      variables: {
        zipCode: Number(zipCode)
      }
    });
    console.log("getZip: ", response.data, response.error)
    if( response.data === null ) {
      blankZip();
    } else {
      setZipCodeData(response.data);
    }
  }

  const getPropTypes = async () => {
    const response = await client.query({
      query: PROPERTY_TYPES_QUERY
    });
   console.log("Got propertyTypes", response.data)
    setIsLoading(false);
    if( response.data !== null ) {
      setPropertyTypes(response.data);
    }
  }

  /*
  const [getPropTypesO] = useLazyQuery(PROPERTY_TYPES_QUERY, {
    onCompleted: (data) => {
      console.log("Got propertyTypes", data)
      setIsLoading(false);
      setPropertyTypes(data);
    },

    onError: (error) => {
      console.log("error propertyTypes")
      setIsLoading(false);
      setError(error.message); 
    }
  });
  */

  useEffect(() => {
    if( propertyTypes?.propertyTypes && propertyTypes?.propertyTypes.length <= 0 ) { 
      setIsLoading(false);
      return;
    } else {
      getPropTypes();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]); 

  useEffect(() => {
    getZip();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[zipCode])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    console.log("Handled change: ", event.currentTarget.value, event.currentTarget.id)
    switch(event.currentTarget.id) {
      case "zipCode":
        setProperty({
          ...property,
          zipCode: event.currentTarget.value
        } as IProperty);
        setZipCode( event.currentTarget.value );
        break;
      case "url":
      case "addr1":
      case "addr2":
        setProperty({
          ...property,
          [event.currentTarget.id]: event.currentTarget.value,
        } as IProperty);
        break;
      default:
        setProperty({
          ...property,
          [event.currentTarget.id]: Number(event.currentTarget.value),
        } as IProperty)
        break;
    };
  }

  const handleSelect: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    console.log("Handled select: ", event.currentTarget.value, event.currentTarget.id)
    setProperty({
      ...property,
      [event.currentTarget.id]: Number(event.currentTarget.value),
    } as IProperty)
  }

  const createProperty = async () => {
    setIsCreating(true);
    console.log("Property to Create: ", property, zipCode)
    client.mutate({
      mutation: QUICK_PROPERTY,
      variables: {
        propertyTypeId: property?.propertyTypeId,
        addr1: property?.addr1,
        ...property,
      }
    })
    .then( (data) => {
      console.log("Then: ", data.data);
      navigate(`/properties/${data.data.createQuickProperty.propertyId}`)
    })
    .catch( (error) => {
      console.log("Error! ", error);
      setError(error[0])
    })
    .finally(() => {
      console.log("Finally!")
      setIsCreating(false);
    })
  }

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    createProperty()
  }

  if( isLoading ) return (<h2>Loading...</h2> );
  if( isCreating ) return (<h2>Creating Property...</h2> );
  if( error ) return (<h2>{error}</h2> );

  return (
    <React.Fragment>
    <h2>Property Information</h2>
    <form  onSubmit={handleSubmit}>
      <div className="form-group">
        <Table bordered size="sm">
          <tbody>
            <tr>
              <td>Property Type:</td>
              <td>
                <Dropdown id="propertyTypeId" className="form-control" handleChange={handleSelect} selectText="Pick one..." >
                  { propertyTypes?.propertyTypes.map( propertyType=>(<Option key={propertyType.id} value={propertyType.id.toString()} text={propertyType.name}/>) ) }
                </Dropdown>
              </td>
            </tr>
            <tr>
              <td>Address:</td>
              <td>
                <TextField id="addr1" placeHolder="Property number and street" handleChange={handleChange} />
              </td>
            </tr>
            <tr>
              <td>Zip Code:</td>
              <td>
                <TextField id="zipCode" placeHolder="Zip Code (Postal Code)" handleChange={handleChange} />
              </td>
            </tr>
            <tr>
              <td>City:</td>
              <td>{zipCodeData?.postalCode.city}</td>
            </tr>
            <tr>
              <td>State:</td>
              <td>{zipCodeData?.postalCode.stateCode}</td>
            </tr>
            <tr>
              <td>URL:</td>
              <td>
                <TextField id="url" placeHolder="Zillow, Redfin, Realtor.com URL" handleChange={handleChange} />
              </td>
            </tr>
            <tr>
              <td>Price:</td>
              <td><TextField id="price" placeHolder="Property price" handleChange={handleChange} /></td>
            </tr>
            <tr>
              <td>Percent Down:</td>
              <td><TextField id="percentDown" placeHolder="" handleChange={handleChange} /></td>
            </tr>
            <tr>
              <td>Loan Rate:</td>
              <td><TextField id="rate" placeHolder="" handleChange={handleChange} /></td>
            </tr>
            <tr>
              <td>Units:</td>
              <td><TextField id="units" placeHolder="Total units (1 for home, condo, townhome)" handleChange={handleChange} /></td>
            </tr>
            <tr>
              <td>Rent:</td>
              <td><TextField id="rent" placeHolder="Rental income for property" handleChange={handleChange} /></td>
              <td><Frequency handleChange={handleSelect} id="frequency" className="form-control" /></td>
            </tr>
          </tbody> 
        </Table>
      </div>
      <Button variant="primary" type="submit"> Submit </Button>
    </form>
    </React.Fragment>
  );
}

export default PropertyNew;