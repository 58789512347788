import { gql } from "@apollo/client";

export const FRAGMENT_DICTIONARY = gql`
  fragment dictionaryFields on Dictionary {
    id
    term
    definition
  }
`;

export const FRAGMENT_LIST = gql`
  fragment listFields on List {
    id
    listOrder
    title
    description
  }
`;

export const FRAGMENT_METRO = gql`
  fragment metroFields on Metro {
    fip
    stateCode
    name
    cbsaCode
  }
`;

export const FRAGMENT_APARTMENT = gql`
  fragment apartmentFields on Apartment {
    month
    onebed
    twobed
    vacancy
  }
`;

export const FRAGMENT_HUDAPARTMENT = gql`
  fragment hudApartmentFields on Hudapartment {
    id
    zipCode
    year
    studio
    onebed
    twobed
    threebed
    fourbed
  }
`;

export const FRAGMENT_PROPERTY_BALANCE = gql`
  fragment propertyBalanceFields on PropertyBalance { 
    id
    name
    amount
  }
`;

export const FRAGMENT_LOAN = gql`
  fragment loanFields on Loan {
    loanId
    principle
    downPayment
    percentDown
    rate
    length
    closingCost
    paymentsPerAnnum
    investment
    debtService
    startDate
  }
`;

export const FRAGMENT_UNITS = gql`
  fragment unitFields on Unit {
    unitId
    unitType
    type
    number
    rent
    rentFrequency
    minimumStay
    cleaningFee
    serviceFee
    occupancyRate
    beds
    baths
  }
`;

export const FRAGMENT_PROPERTY = gql`
  fragment propertyFields on Property {
      propertyId
      fullAddress
      type
      addr1
      addr2
      stateCode
      zipCode
      price
      noi
      grm
      expense
      investment
      income
      debtService
      debtCoverageRatio
      capRate
      onePercent
      roi
      cashFlow
      sqft
      lotSize
      city
      url
      zipCode
  }
`;

export const FRAGMENT_POSTAL_CODE = gql`
  fragment postalCodeFields on PostalCode {
    stateCode
    countyFip
    metroFip
    airbnbOccupancyRate
    city
    latitude
    longitude
  }
`;

export const FRAGMENT_STATE = gql`
  fragment stateFields on State {
    number
    name
    region
    regionName
    division
    divisionName
    propertyTaxRate
  }
`;