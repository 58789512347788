import { gql } from "@apollo/client";
import {
  FRAGMENT_LOAN,
  FRAGMENT_POSTAL_CODE,
  FRAGMENT_PROPERTY,
  FRAGMENT_PROPERTY_BALANCE,
  FRAGMENT_STATE,
  FRAGMENT_UNITS,
  FRAGMENT_APARTMENT,
  FRAGMENT_HUDAPARTMENT,
  FRAGMENT_METRO,
  FRAGMENT_DICTIONARY,
  FRAGMENT_LIST,
} from "./graphFragments";

export const LIST_QUERY = gql`
  ${FRAGMENT_LIST}
  query Lists {
    lists {
      ...listFields
      children {
        ...listFields
      }
    }
  }
`;

export const LIST_CREATE = gql`
  ${FRAGMENT_LIST}
  mutation CreateList($title: String!, $description: String!, $parentId: Int, $listOrder: Int) {
    createList(title: $title, description: $description, parentId: $parentId, listOrder: $listOrder) {
      ...listFields
    }
  }
`;

export const LIST_UPDATE = gql`
  ${FRAGMENT_LIST}
  mutation UdateList($id: Int!, $title: String, $description: String, $listOrder: Int) {
    updateList(id: $id, title: $title, description: $description, listOrder: $listOrder) {
      ...listFields
    }
  }
`;

export const LIST_DELETE = gql`
  ${FRAGMENT_LIST}
  mutation DeleteList($id: Int!) {
    deleteList(id: $id) {
      ...listFields
    }
  }
`;

export const DICTIONARY_QUERY = gql`
  ${FRAGMENT_DICTIONARY}
  query Dictionary {
    dictionaries {
      ...dictionaryFields
    }
  }
`;

export const DICTIONARY_CREATE = gql`
  ${FRAGMENT_DICTIONARY}
  mutation CreateDictionary($term: String!, $definition: String!) {
    createDictionary(term: $term, definition: $definition) {
      ...dictionaryFields
    }
  }
`;

export const DICTIONARY_DELETE = gql`
  ${FRAGMENT_DICTIONARY}
  mutation DreateDictionary($id: Int!) {
    deleteDictionary(id: $id) {
      ...dictionaryFields
    }
  }
`;

export const METRO_QUERY = gql`
  ${FRAGMENT_METRO}
  query Metro($fip: Int, $name: String, $zipCode: Int) {
    metros(fip: $fip, name: $name, postalCode: $zipCode) {
      ...metroFields
    }
  }
`;

export const APARTMENT_QUERY = gql`
  ${FRAGMENT_APARTMENT}
  query AprtmentsZip($zipCode: Int, $metroFip: Int) {
    apartment(postalCode: $zipCode, metroFip: $metroFip) {
      ...apartmentFields
    }
  }
`;

export const HUDAPARTMENT_QUERY = gql`
  ${FRAGMENT_HUDAPARTMENT}
  query Hudaprtments($zipCode: Int) {
    hudapartment(postalCode: $zipCode) {
      ...hudApartmentFields
    }
  }
`;

export const UPDATE_LOAN = gql`
  ${FRAGMENT_LOAN}
  mutation UpdateLoan($loanId: Int!, $percentDown: Float, $downPayment: Int, $rate: Float, $length: Int, $closingCost: Int) {
    updateLoan(loanId: $loanId, percentDown: $percentDown, downPayment: $downPayment, rate: $rate, length: $length, closingCost: $closingCost) {
      ...loanFields
    }
  }
`;

// ---------------------------------------------------------------
// Property types
// ---------------------------------------------------------------
export const PROPERTY_TYPES_QUERY = gql`{
  propertyTypes {
    id,
    name,
    description
  }
}`;

export const NEW_PROPERTY_TYPE = gql`
  mutation CreatePropertyType($name: String!, $description: String) {
    createPropertyType(name: $name, description: $description) {
      id
      name
      description
    }
  }
`;
export const DELETE_PROPERTY_TYPE = gql`
  mutation deletePropertyType($id: Int!) {
    deletePropertyType(id: $id) {
      name
      description
    }
  }
`;

// ---------------------------------------------------------------
// Expenses stuff
// ---------------------------------------------------------------
export const PROPERTY_EXPENSES_QUERY = gql`
  ${FRAGMENT_PROPERTY_BALANCE}
  query PropertyExpenses($propertyId: Int!) {
    propertyExpenses(propertyId: $propertyId) {
      ...propertyBalanceFields
    }
  }
`;

export const EXPENSE_TYPES_QUERY = gql`{
  expenseTypes {
    id,
    name,
    description 
  }
}`;

export const NEW_EXPENSE_TYPE = gql`
  mutation CreateExpenseType($name: String!, $description: String) {
    createExpenseType(name: $name, description: $description) {
      id
      name
      description
    }
  }
`;
export const DELETE_EXPENSE_TYPE = gql`
  mutation DeleteExpenseType($id: Int!) {
    deleteExpenseType(id: $id) {
      name
      description
    }
  }
`;
export const CREATE_PROPERTY_EXPENSE = gql`
  mutation CreatePropertyExpense($propertyId: Int!, $expenseIncomeTypeId: Int!, $amount: Float!) {
    createPropertyExpense(propertyId: $propertyId, expenseIncomeTypeId: $expenseIncomeTypeId, amount: $amount) {
      id
      name
      amount
    }
  }
`;
export const UPDATE_PROPERTY_EXPENSE = gql`
  mutation UpdatePropertyExpense($id: Int!, $amount: Float!) {
    updatePropertyExpense(id: $id, amount: $amount) {
      id
      name
      amount
    }
  }
`;
export const DELETE_PROPERTY_EXPENSE = gql`
  mutation DeletePropertyExpense($id: Int!) {
    deletePropertyExpense(id: $id) {
      name
      amount
    }
  }
`;

// ---------------------------------------------------------------
// Expense types
// ---------------------------------------------------------------
export const INCOME_TYPES_QUERY = gql`{
  incomeTypes {
    id,
    name,
    description 
  }
}`;

export const NEW_INCOME_TYPE = gql`
  mutation CreateIncomeType($name: String!, $description: String) {
    createIncomeType(name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const DELETE_INCOME_TYPE = gql`
  mutation DeleteIncomeType($id: Int!) {
    deleteIncomeType(id: $id) {
      name
      description
    }
  }
`;

export const POSTAL_CODE_QUERY = gql`
  ${FRAGMENT_STATE}
  ${FRAGMENT_POSTAL_CODE}
  query PostalCode($zipCode: Int!) {
    postalCode(zipCode: $zipCode) {
      ...postalCodeFields
      state {
        ...stateFields 
      }  
    }
  }
`;

export const RECALC_QUERY = gql`
  ${FRAGMENT_PROPERTY}
  query Recalc($propertyId: Int!) {
    recalc(propertyId: $propertyId) {
      ...propertyFields
    }
  }
`;

export const PROPERTY_QUERY = gql`
  ${FRAGMENT_LOAN}
  ${FRAGMENT_UNITS}
  ${FRAGMENT_PROPERTY}
  ${FRAGMENT_POSTAL_CODE}
  ${FRAGMENT_STATE}
  query Property($propertyId: Int!) {
    property(propertyId: $propertyId) {
      ...propertyFields
      postalCode {
        ...postalCodeFields
      }
      state {    
        ...stateFields
      }
      propertyExpenses {
        id
        name
        amount
      }
      propertyIncomes {
        id
        name
        amount
      }
      loans {
        ...loanFields
      }      
      units {
        ...unitFields
      }
    }
  }
`;

export const QUICK_PROPERTY = gql`
  mutation QuickPropertyQuery(
    $propertyTypeId: Int!,
    $addr1: String!
    $zipCode: String!,
    $rent: Float!,
    $rate: Float!,
    $percentDown: Float!,
    $price: Float!,
    $units: Int,
    $url: String, 
  ) {
    createQuickProperty(
      propertyTypeId: $propertyTypeId,
      addr1: $addr1,
      zipCode: $zipCode,
      rent: $rent,
      rate: $rate,
      percentDown: $percentDown,
      price: $price,
      units: $units,
      url: $url,
    ) {
      propertyId
      fullAddress
    }
  }
`;

export const PROPERTIES_QUERY = gql`
  ${FRAGMENT_PROPERTY}
  query Properties {
    properties {
      ...propertyFields
    }
  }
`;


// ---------------------------------------------------------------
// Queries for model types
// ---------------------------------------------------------------

export const RAW_UNIT_TYPES_QUERY = `{
  unitTypes {
    key,
    value
  }
}`;
export const UNIT_TYPES_QUERY = gql(RAW_UNIT_TYPES_QUERY);