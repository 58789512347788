import { currencyColored } from './Formats';
import { Card, Table } from 'react-bootstrap';
import { IPropertyBalance } from '../types/Property';
import { Link } from 'react-router-dom';
import { useApolloClient } from "@apollo/client";
import { RECALC_QUERY } from '../platform/graphql';
import { useNavigate } from 'react-router-dom';

interface IExpenseIncomeParams {
  title: string;
  showTotal?: boolean;
  height?: number;
  showAnnual?: boolean;
  propertyId?: number;
  expenseIncome?: IPropertyBalance[];
}

export const ExpenseIncomeCard = (props: IExpenseIncomeParams) => {
const {showTotal, showAnnual, expenseIncome, height, propertyId} = props;
const client = useApolloClient();
let total = 0;
let width = showAnnual ? '23rem' : '18rem';
let navigate = useNavigate();

const recalc: React.MouseEventHandler = (event) => {
  event.preventDefault();
  console.log("Recalc...", event)
  client.query({
    query: RECALC_QUERY,
    variables: {
      propertyId: propertyId
    }
  })
  .then((data) => {
    navigate(`/properties/${propertyId}`)
  });
}

  return (
    <Card style={{ width: width, height: height || '410px' }}>
    <Card.Body>
      <Card.Title>{props.title}</Card.Title>
      <Table borderless hover size="sm">
        <thead>
        <tr>
          <th/>
          <th>Monthly</th>
          {showAnnual && (<th>Annualy</th>)}
        </tr>
        </thead>
        <tbody>
        {
          expenseIncome && expenseIncome.map(expense=>{
            total = total + expense.amount;
            return (
            <tr key={expense.name}>
              <td>{expense.name}</td>
              <td>{currencyColored(expense.amount)}</td>
              {showAnnual && (<td>{currencyColored(expense.amount * 12)}</td>)}
            </tr>
            )
          })
        }
        <tr><td/><td/></tr>
        {
          showTotal && (
          <tr style={{fontWeight: 'bold'}}>
            <td>Total:</td>
            <td>{currencyColored(total)}</td>
            {showAnnual && (<td>{currencyColored(total * 12)}</td>)}
          </tr>
          )
        }
        </tbody>
      </Table>
      <Link to={`/properties/${propertyId}/expenses`}>Manage</Link>
      <br/>
      <Link to={'/recalc'} onClick={recalc}>Recalc</Link>
    </Card.Body>
    </Card>

  );

}

ExpenseIncomeCard.defaultProps = {
  showTotal: true
};