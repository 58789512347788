import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { ExpenseIncomeCard } from '../ExpenseIncomeCard';
import { Carousel, Table } from 'react-bootstrap';
import { PropertyCard } from './PropertyCard';
import { PropertyBalanceCard } from './PropertyBalanceCard';
import { UnitCard } from '../UnitCard';
import { LoanCard } from '../LoanCard';
import { useAppDispatch, useAppSelector } from '../../platform/redux/hooks';
import { FetchProperty } from '../../platform/redux/propertySlice';

const PropertyShow = () => {
  const {propertyId} = useParams();

  // const state = useAppSelector((state) => state.property);
  // const { property, status } = state;
  const { property, status, updated } = useAppSelector((state) => state.property);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(FetchProperty(Number(propertyId))); 
  }, [dispatch]);

  if (status === 'loading') {
    return (<h3>Loading</h3>)
  }

  const loans = property.loans;
  const expenses = property.propertyExpenses;
  const units = property.units;

  // const {loading, error, data} = useQuery<{property: IProperty},IParams>(PROPERTY_QUERY, {variables: {propertyId: Number(propertyId)}} );
  // if (loading) return <h2>Loading...</h2>
  // if (error) return <pre>{error.message}</pre>
  // const loans = data?.property.loans;
  // const expenses = data?.property.propertyExpenses;
  // const units = data?.property.units;

  return (
    <>
    <h3>{updated ? 'Changes made' : ''}</h3>
    <Table style={{width: '10px'}}>
      <tbody>
      <tr>
        <td valign='top'>
          <PropertyCard property={property} />
        </td>
        <td valign='top'>
           <PropertyBalanceCard property={property} />
        </td>
        <td valign='top'>
          <Carousel>
          {units && units.map(unit=> {
            return(
              <Carousel.Item key={unit.unitId}>
               <UnitCard unit={unit} />
              </Carousel.Item>
            )
          })}
          </Carousel>
        </td>
      </tr>
      <tr>
        <td valign='top'>
          <ExpenseIncomeCard title='Expenses' propertyId={Number(propertyId)} expenseIncome={expenses} showAnnual={true} />
        </td>
        <td valign='top'>
          <Carousel>
          {loans && loans.map(loan=> {
            return(
              <Carousel.Item key={loan.loanId}>
               <LoanCard propertyId={Number(propertyId)} loan={loan} />
              </Carousel.Item>
            )
          })}
          </Carousel>
        </td>
      </tr>
      </tbody>
    </Table>
    </>
  );
}

// function mapStateToProps(state: { property: IPropertySliceState; }) {
  // const { property } = state
  // return property;
// }
// export default connect(mapStateToProps)(PropertyShow)
export default PropertyShow;