import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { IApartments } from "../../types/Apartment";
import { getRandomColor, monthYearFromMonth } from "../Formats";
import { IMetro } from "../../types/Metro";
import MetroApartments from "../MetroApartments";

const ChartApartments = () => {
  const [metro, setMetro] = useState<IMetro>();
  const [apartments, setApartments] = useState<IApartments>();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Apartment Prices',
      },
    },
  };

  const formatLabel = (data:IApartments) => {
    if( typeof(data) === "undefined") return "";
    let labels = [];
    for(var i=0; i<data.apartment.length; i++) {
      labels.push( monthYearFromMonth( data.apartment[i].month ) )
    }
    return labels;
  }

  const getDataSet = (key:string, data?:IApartments) => {
    if( typeof(data) === "undefined") return;

    let ret:number[] = [];
    for(var i=0; i<data.apartment.length; i++) {
      const el = data.apartment[i]; //interface: IApartment
      const keyGet = key as keyof typeof el; //define keyGet as interface type
      ret.push( Number(el[keyGet]) )
    }

      // backgroundColor: 'rgba(1,2,2,0)',
      // borderColor: getRandomColor(),
    const dataSet = {
      label: key.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()),
      backgroundColor: getRandomColor(),
      data: ret
    }

    return dataSet;
  }


  const onMetroApartments = (metro:IMetro, apartments: IApartments) => {
    setMetro(metro);
    setApartments( apartments );
  }


  let labels:any = [];
  let rents:any = {}
  let vacancies:any = {}

  if(apartments?.apartment && apartments.apartment.length > 0 ) {
    labels = formatLabel(apartments);
    rents = {
      labels,
      datasets: [
        getDataSet('onebed', apartments),
        getDataSet('twobed', apartments),
      ]
    }

    vacancies = {
      labels,
      datasets: [
        getDataSet('vacancy', apartments)
      ]
    }

  }

  return (
    <React.Fragment>
      <MetroApartments onMetroApartmnets={onMetroApartments}/>
      { metro ? <h4>Metro: {metro.name}</h4> : '' }
      {
        apartments?.apartment && apartments.apartment.length > 0 ? 
            <>
            <Line datasetIdKey='id' 
                  options={options} 
                  data={rents}
            />
            <Bar datasetIdKey='id' 
                  options={options} 
                  data={vacancies}
            />
            </>
          : metro ? <h6>No apartment data</h6> : ""
      }
    </React.Fragment>
  );
}

export default ChartApartments;