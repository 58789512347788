import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import {Option, Dropdown,TextField} from "../Form";
import { IPropertyExpenses } from '../../types/Property';
import { CREATE_PROPERTY_EXPENSE, DELETE_PROPERTY_EXPENSE, EXPENSE_TYPES_QUERY, PROPERTY_EXPENSES_QUERY, UPDATE_PROPERTY_EXPENSE } from "../../platform/graphql";
import { Button, Table } from "react-bootstrap";
import { useApolloClient, useQuery } from "@apollo/client";
import Editable from "../Editable";
import { IExpenseTypes } from "../../types/ExpenseIncomeType";

const PropertyExpenses = () => {
  let {propertyId} = useParams();

  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [expenseTypes, setExpenseTypes] = useState<IExpenseTypes>();
  const [expenseIncomeTypeId, setExpenseIncomeTypeId] = useState<number>();
  const [newAmount, setNewAmount] = useState<string>();

  useEffect(() => {
    client.query({
      query: EXPENSE_TYPES_QUERY
    })
    .then((data) => {
      setIsLoading(false);
      setExpenseTypes(data.data);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const {loading, error, data, refetch} = useQuery<IPropertyExpenses>(PROPERTY_EXPENSES_QUERY, {variables: {propertyId: Number(propertyId)}});
  if (loading) return <h2>Loading...</h2>
  if (error) return <pre>{error.message}</pre>


  if( loading || isLoading) return (<h2>Loading...</h2> );
  if( error ) return (<h2>{error}</h2> );

  const handleSelect: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    setExpenseIncomeTypeId( Number(event.currentTarget.value) );
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    console.log("Handled change: ", event.currentTarget.value, event.currentTarget.id)
    setNewAmount( event.currentTarget.value );
  }

  const handleAdd = () => {
    console.log("handleAdd");
    client.mutate({
      mutation: CREATE_PROPERTY_EXPENSE,
      variables: {
        propertyId: Number(propertyId),
        expenseIncomeTypeId: Number(expenseIncomeTypeId),
        amount: Number(newAmount),
      }
    })
    .then(() => {
      refetch();
    });
  }

  const handleUpdate = (id: string, amount: string) => {
    console.log("handleUpdate: ", id);
    client.mutate({
      mutation: UPDATE_PROPERTY_EXPENSE,
      variables: {
        id: Number(id),
        amount: Number(amount)
      }
    })
    .then(() => {
      console.log("Updated....")
      refetch();
    });
  }

  const handleDelete = (id: Number ) => {
    console.log("handleDelete: ", id);
    if( !window.confirm("Are you sure you want to delete this? ") ) {
      return;
    }
    client.mutate({
      mutation: DELETE_PROPERTY_EXPENSE,
      variables: {
        id: Number(id),
      }
    })
    .then(() => {
      refetch();
      document.getElementById("amount")?.focus();
    })
  }

  return (
    <React.Fragment>
    <h2>Property Expenses</h2>
    <h5><Link to={`/properties/${propertyId}`}>Back to Property</Link></h5>
      <div className="form-group">
        <Table bordered size="sm">
          <thead>
            <tr>
              <th>Expense</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {
            data && data.propertyExpenses.map(expense=>{
              return (
              <tr key={expense.id}>
                <td>{expense.name} </td>
                <td><Editable text={expense.amount.toString()} id={expense.id.toString()} onEnter={handleUpdate}/></td>
                <td style={{border: 0}}><Button variant="danger" type="button" onClick={()=>handleDelete(expense.id)} > X </Button></td>
              </tr>
              )
            })
          }
            <tr>
              <td>
                <Dropdown id="expenseIncomeTypeId" className="form-control" handleChange={handleSelect} selectText="Pick one..." >
                  { expenseTypes?.expenseTypes.map( expenseType=>(<Option key={expenseType.id} value={expenseType.id.toString()} text={expenseType.name}/>) ) }
                </Dropdown>
              </td>
              <td><TextField id="amount" placeHolder="Expense amount" handleChange={handleChange} /></td>
              <td><Button variant="primary" type="button" onClick={()=>handleAdd()} > Add</Button></td>
            </tr>
          </tbody> 
        </Table>
      </div>
    </React.Fragment>
  );
}

export default PropertyExpenses;