import { currencyColored } from './Formats';
import { Card, Table } from 'react-bootstrap';
import { ILoan } from '../types/Loan';
import Editable from './Editable';
import { useState } from 'react';
import { UPDATE_LOAN } from '../platform/graphql';
import { useApolloClient } from '@apollo/client';
import { useAppDispatch, useAppSelector } from '../platform/redux/hooks';
import { updateLoan } from '../platform/redux/propertySlice';

interface ILoanParams {
  height?: number;
  propertyId?: number;
  loan: ILoan;
}

export const LoanCard = (props: ILoanParams) => {
  const {loan, height} = props;
  const [stateLoan, setStateLoan] = useState<ILoan>(loan);
  const client = useApolloClient();
  const dispatch = useAppDispatch();

  const loanUpdated = useAppSelector(state => state.property.loanUpdated);
  // const loan = useAppSelector(state => getLoan(state, pLoan.loanId));
  // console.log("LOANS: ", loan)
  // setStateLoan(loan);

  const onEnter = (id: string, amount: string) => {
    // var data: ILoan;
    var data = {}
    switch(id) {
      case "percent_down":
        data = {percentDown: Number(amount), loanId: loan.loanId}
        break;
      case "down_payment":
        data = {downPayment: Number(amount), loanId: loan.loanId}
        break;
      case "rate":
        data = {rate: Number(amount), loanId: loan.loanId}
        break;
      case "length":
        data = {length: Number(amount), loanId: loan.loanId}
        break;
      case "closing_cost":
        data = {closingCost: Number(amount), loanId: loan.loanId}
        break;
    }

    client.mutate({
      mutation: UPDATE_LOAN,
      variables: data
    })
    .then((data) => {
      console.log("Response: ", data.data)
      setStateLoan(data.data.updateLoan);
    });

    dispatch( updateLoan({loanId: loan.loanId, key: id, value: Number(amount)}) )

    /* API way
    Put(`/properties/${propertyId}/loans/${loan.loanId}`,data)
    .then((json) => {
      setStateLoan(json)
    });
    */
  }

  return (
    <Card style={{ width: '23rem', height: height || '410px' }}>
    <Card.Body>
      <Card.Title>Loan</Card.Title>
      <Card.Subtitle>{loanUpdated===loan.loanId ? 'True' : ''}</Card.Subtitle>
      <Table borderless hover size="sm">
        <tbody>
        <tr>
          <td>Principle:</td>
          <td>{currencyColored(stateLoan.principle)}</td>
        </tr>
        <tr>
          <td>Percent Down:</td>
          <td>
            <Editable id="percent_down" type="Percent" text={stateLoan.percentDown.toString()} onEnter={onEnter}/>
          </td>
        </tr>
        <tr>
          <td>Down Payment:</td>
          <td><Editable id="down_payment" type="Currency" text={stateLoan.downPayment.toString()} onEnter={onEnter}/></td>
        </tr>
        <tr>
          <td>Rate:</td>
          <td><Editable id="rate" type="Percent" text={stateLoan.rate.toString()} onEnter={onEnter}/></td>
        </tr>
        <tr>
          <td>Length:</td>
          <td><Editable id="length" type="Number" text={stateLoan.length.toString()} onEnter={onEnter}/></td>
        </tr>
        <tr>
          <td>Closing Costs:</td>
          <td><Editable id="closing_cost" type="Currency" text={ (stateLoan.closingCost ? stateLoan.closingCost : 0).toString() } onEnter={onEnter}/></td>
        </tr>
        <tr><td/><td/></tr>
        <tr style={{fontWeight: 'bold'}}>
          <td>Total Investment:</td>
          <td>{currencyColored(stateLoan.investment)}</td>
        </tr>
        <tr style={{fontWeight: 'bold'}} >
          <td>Debt Service:</td>
          <td>{currencyColored(stateLoan?.debtService)}</td>
        </tr>
        </tbody>
      </Table>
    </Card.Body>
    </Card>
  );
}