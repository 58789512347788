import { Tab, Tabs } from "react-bootstrap";
import { ExpenseTypes } from "./ExpenseTypes";
import { IncomeTypes } from "./IncomeTypes";
import { PropertyTypes } from "./PropertyTypes";

export const TypesTabs = () => {
  return (
    <Tabs defaultActiveKey="property" id="uncontrolled-tab-example" className="mb-3">
      <Tab eventKey="property" title="Property">
        <PropertyTypes />
      </Tab>
      <Tab eventKey="expenses" title="Expense">
        <ExpenseTypes />
      </Tab>
      <Tab eventKey="incomes" title="Income">
        <IncomeTypes />
      </Tab>
    </Tabs>
  );
}
