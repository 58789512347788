import { currencyColored, currencyFormat, numberFormat } from '../Formats';
import { Card, Table } from 'react-bootstrap';
import { IProperty } from '../../types/Property';
import { Link } from 'react-router-dom';

interface IPropertyCardParams {
  property?: IProperty;
  height?: number;
}

export const PropertyCard = (props: IPropertyCardParams) => {
  const {property, height} = props;
  if( typeof(property)==='undefined') return null

  return (
    <Card style={{ width: '23rem', height: height || '410px' }}>
    <Card.Body>
      <Card.Subtitle>{property.addr1}</Card.Subtitle>
      <Card.Subtitle>{property.city}, {property.stateCode} {property.zipCode}</Card.Subtitle>
      <Table borderless hover size="sm">
        <tbody>
        <tr>
          <td>Type:</td>
          <td>{property.type}</td>
        </tr>
        <tr>
          <td>URL:</td>
          <td><a target="_blank" rel="noreferrer" href={property.url} >Link</a></td>
        </tr>
        <tr>
          <td>Price:</td>
          <td>{currencyFormat(property.price)}</td>
        </tr>
        <tr>
          <td>SQFT:</td>
          <td>{numberFormat(property.sqft)}</td>
        </tr>
        <tr>
          <td>Lot Size:</td>
          <td>{numberFormat(property.lotSize)}</td>
        </tr>
        <tr>
          <td>Expenses:</td>
          <td>{currencyColored(property.expense)}</td>
        </tr>
        </tbody>
      </Table>
      <Link to={`/properties/${property.propertyId}/edit`}>Manage</Link>
    </Card.Body>
    </Card>
  );
}