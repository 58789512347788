import React, { useState, useEffect } from "react";
import { TextField} from "./Form";
import { Button, Table } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import { DICTIONARY_QUERY, DICTIONARY_CREATE, DICTIONARY_DELETE} from "../platform/graphql";
import { IDictionary, IDictionaries } from "../types/Dictionary";

type newDictionaryType = Omit<IDictionary, "id">;

const Dictionary = () => {
  const [crud, setCrud] = useState<boolean>(false);
  const [dictionary, setDictionary] = useState<newDictionaryType>({term:"",definition: ""});

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[crud])

  const [deleteDictionary] = useMutation(DICTIONARY_DELETE);
  const [createDictionary] = useMutation(DICTIONARY_CREATE, {
    variables: {
      term: dictionary.term,
      definition: dictionary.definition,
    }
  });

  const {loading, error, data, refetch} = useQuery<IDictionaries>(DICTIONARY_QUERY);
  if (loading) return <h2>Loading...</h2>
  if (error) return <pre>{error.message}</pre>

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    let str = event.currentTarget.value.replace(/^\s+/,'') ;
    // str = str.replace(/\s+$/,'');
    switch(event.currentTarget.name) {
      case "term":
        setDictionary({
          ...dictionary,
          term: str
        } as newDictionaryType)
        break;
      case "definition":
        setDictionary({
          ...dictionary,
          definition: str
        } as newDictionaryType)
        break;
    };
  }

  const onKeyUp: React.KeyboardEventHandler<HTMLDivElement>  = (event) => {
    const { key } = event;
    if( key === "Enter" ) {
      handleNew();
    }
  };

  const handleDelete = async (id: number, name: string) => {
    if( window.confirm("Are you sure you want to delete property type: " + name + "?") ) {
      await deleteDictionary({
        variables: { id: Number(id) },
      });
      setCrud(!crud);
    } 
  }

  const handleNew =  async () => {
    if( dictionary.term==="" || dictionary.term.length <= 0 ) {
      window.alert("Enter a valid name");
      return;
    }
    if( dictionary.definition==="" || dictionary.definition.length <= 0 ) {
      window.alert("Enter a valid definition");
      return;
    }

    await createDictionary();
    setDictionary({term: "", definition: ""});
    setCrud(!crud);
  }

  return (
    <React.Fragment>
    <h2>Dictionary</h2>
      <Table bordered size="sm">
        <thead>
          <tr>
            <th>Term</th>
            <th>Definition</th>
          </tr>
        </thead>
        <tbody>
          {
            data && (data.dictionaries).map(pt => {
              return (
              <tr key={pt.id}>
                <td>{pt.term}</td>
                <td id={pt.id.toString()}>{pt.definition}</td>
                <td><Button onClick={()=>handleDelete(pt.id, pt.term)} variant="danger" type="button"> delete </Button></td>
              </tr>)
            }) 
          }
          <tr>
            <td><TextField id="term" placeHolder="Term" autoFocus={true} handleChange={handleChange} value={dictionary.term}/></td>
            <td><TextField id="definition" placeHolder="definition" handleChange={handleChange} onKeyUp={onKeyUp} value={dictionary.definition} /></td>
            <td><Button onClick={()=>handleNew()} variant="success" type="submit"> Create </Button></td>
          </tr>
        </tbody> 
      </Table>
    </React.Fragment>
  );
}

export default Dictionary;