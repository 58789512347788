import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { IMetro, IMetros } from "../types/Metro";

const MetroList = (props: IMetroListParams) => {
  const [hide,setHide] = useState<boolean>(false);

  if(!props.metros ) return (<></>)

  //https://icons.getbootstrap.com/
  return (
    <React.Fragment>
          <tr><td colSpan={2}><br/></td></tr>
          <tr>
            <td><div style={{fontWeight: 'bold'}} onClick={()=>setHide(!hide)}>Metro
              {hide ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5z"/>
                </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-bar-up" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/>
                  </svg>
                )
              }
            </div>
            </td>
            <td></td>
          </tr>
          { 
          !hide && props.metros.metros.map(metro => {
            return(
                <tr key={metro.fip}> 
                  <td>{metro.name}, {metro.stateCode} <span style={{color: 'white'}}>FIP: {metro.fip}  CBSA {metro.cbsaCode}</span></td>
                  <td><Button onClick={()=>{setHide(true); props.onMetro(metro)}}>Select</Button></td>
                </tr>
              )
          })
          }
    </React.Fragment>
  );
}

interface IMetroListParams {
  metros?: IMetros;
  onMetro: (metro: IMetro) => void;
}

export default MetroList;