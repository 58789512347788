import React from "react";
import { useApolloClient } from "@apollo/client";
import { APARTMENT_QUERY } from "../platform/graphql";
import { IApartments } from "../types/Apartment";
import { IMetro } from "../types/Metro";
import Metro from "./Metro";

const MetroApartments = (props: IMetroAptParams) => {

  const client = useApolloClient();

  const getApartments = (metro: IMetro) => {
    client.query({
      query: APARTMENT_QUERY,
      variables: {
        metroFip: metro.fip
      }
    })
    .then((data) => {
      props.onMetroApartmnets(metro, data.data);
    })
    .catch((error) => console.log(error));
  }

  const onMetro = (metro:IMetro) => {
    console.log("MetroApt: ", metro);
    getApartments(metro);
  }

  return (
    <React.Fragment>
      <Metro onMetro={onMetro} />
    </React.Fragment>
  );
}

interface IMetroAptParams {
  onMetroApartmnets: (metro: IMetro, apartments: IApartments) => void;
}

export default MetroApartments;