import React, { ChangeEventHandler, useState } from 'react';
import { FaAngleRight, FaAngleDown } from "react-icons/fa";


export const TextField = (props: ITextField) => {
  return (
    <input
    className={props.className || 'form-control'}
    onChange={props.handleChange}
    id={props.id}
    name={props.id}
    type={props.type || "text"}
    autoFocus={props.autoFocus?true:false}
    placeholder={props.placeHolder || ""}
    onKeyUp={props.onKeyUp}
    value={props.value}
    />
  )
}

export const Option = (props: IOptionProps) => {
  return (
    <option value={props.value} >{props.text}</option>
  )
}

export const Dropdown = (props: IDropDownProps) => {
  return (
    <select
      onChange={props.handleChange}
      className={props.className || 'form-control'}
      id={props.id}
      defaultValue='!'
      name={props.id}
    >
      {props.selectText && <option disabled value='!'>{props.selectText}</option> }
      {props.children}
    </select>
  )
};

export const Frequency = (props: IDropDownProps) => {
  return (
    <Dropdown {...props} >
      <Option key={1} value="1" text="Annual" />
      <Option key={12} value="12" text="Monthly" />
      <Option key={52} value="52" text="Weekly" />
    </Dropdown>

  );
}

export const HideOrShow = (props: IHideOrShow) => {
  const [hidden, setHidden] = useState<boolean>( props.hidden || false )
  let cols = props.cols || 0;
  if(props.button) cols -= 1;
  return (
    <>
    <tr onClick={()=>setHidden(!hidden)}>
      <td>
        {hidden ? <FaAngleRight /> : <FaAngleDown/>}
        <b style={{fontWeight: 'bold'}}>{props.title}</b>
      </td>
      <td>{props.description || ""}</td>
      {props.button && <td>{props.button}</td>}
      {cols && cols > 2 ? <td colSpan={cols -2}/> : ""}
    </tr>
   {!hidden && props.children}
   </>
  )
}

interface ITextField {
  id: string;
  handleChange: React.ChangeEventHandler;
  onKeyUp?: React.KeyboardEventHandler;
  className?: string;
  placeHolder?: string;
  value?: string|number;
  type?: ("text"|"number");
  autoFocus?: boolean
}

interface IOptionProps {
  value: string;
  text: string;
  className?: string;
}

interface IDropDownProps {
  id: string;
  handleChange: ChangeEventHandler<HTMLSelectElement>;
  children?: JSX.Element[] ;
  className?: string;
  selectText?: string;
}

interface IHideOrShow {
  hidden?: boolean;
  title: string;
  description?: string;
  cols?: number;
  children?: JSX.Element | JSX.Element[];
  button?: JSX.Element | JSX.Element[];
}
HideOrShow.defaultProps = {hidden: false}