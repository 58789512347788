import { currencyColored, numberFormat, percentFormat } from '../Formats';
import { Card, Table } from 'react-bootstrap';
import { IProperty } from '../../types/Property';

interface IExpenseIncomeParams {
  property?: IProperty;
  height?: number;
}

export const PropertyBalanceCard = (props: IExpenseIncomeParams) => {
const {property, height} = props;

if( typeof(property)==='undefined') return null;

  return (
    <Card style={{ width: '23rem', height: height || '410px' }}>
    <Card.Body>
      <Table borderless hover size="sm">
        <thead>
        <tr>
          <th/>
          <th>Monthly</th>
          <th>Annualy</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Investment:</td>
          <td/>
          <td>{currencyColored(property.investment)}</td>
        </tr>
        <tr>
          <td>Income:</td>
          <td>{currencyColored(property.income / 12)}</td>
          <td>{currencyColored(property.income)}</td>
        </tr>
        <tr>
          <td>Expense:</td>
          <td>{currencyColored(property.expense)}</td>
          <td>{currencyColored(property.expense * 12)}</td>
        </tr>
        {/* <tr><td colSpan={3}><hr/></td></tr> */}
        <tr style={{fontWeight: 'bold'}}>
          <td>NOI:</td>
          <td>{currencyColored(property.noi / 12)}</td>
          <td>{currencyColored(property.noi)}</td>
        </tr>
        <tr>
          <td>Debt Service:</td>
          <td>{currencyColored(property.debtService)}</td>
          <td>{currencyColored(property.debtService * 12)}</td>
        </tr>
        <tr style={{fontWeight: 'bold'}}>
          <td>Cash Flow:</td>
          <td>{currencyColored(property.cashFlow / 12)}</td>
          <td>{currencyColored(property.cashFlow)}</td>
        </tr>
        <tr><td colSpan={3}><hr/></td></tr>
        <tr>
          <td>DCR:</td>
          <td>{numberFormat(property.debtCoverageRatio)}</td>
          <td>1.2 ~ 1.5</td>
        </tr>
        <tr>
          <td>ROI (CoC):</td>
          <td>{percentFormat(property.roi)}</td>
          <td/>
        </tr>
        <tr>
          <td>Cap Rate:</td>
          <td>{percentFormat(property.capRate)}</td>
          <td/>
        </tr>
        <tr>
          <td>1% Rule:</td>
          <td>{percentFormat(property.onePercent)}</td>
          <td/>
        </tr>
        </tbody>
      </Table>
    </Card.Body>
    </Card>

  );

}