import React, { useState, useEffect } from "react";
import { TextField } from "./Form";
import { Button, Table } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import { LIST_QUERY, LIST_CREATE, LIST_UPDATE, LIST_DELETE} from "../platform/graphql";
import { IList, ILists } from "../types/List";
import Editable from "./Editable";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";

type newListType = Omit<IList, "id">;

const List = () => {
  const [crud, setCrud] = useState<boolean>(false);
  const [list, setList] = useState<newListType>({title:"",description: "", list_order: 0});

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[crud])

  const [deleteList] = useMutation(LIST_DELETE);
  const [createList] = useMutation(LIST_CREATE);
  const [updateList] = useMutation(LIST_UPDATE);

  const {loading, error, data, refetch} = useQuery<ILists>(LIST_QUERY);
  if (loading) return <h2>Loading...</h2>
  if (error) return <pre>{error.message}</pre>

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    let str:any = event.currentTarget.value.replace(/^\s+/,'') ;
    let id = event.currentTarget.id;
    if( id==="list_order" || id==="parent_id" ) str = Number(str);
    setList({
      ...list,
      [id]: str
    } as newListType)
  }

  const handleDelete = async (id: number, name: string) => {
    if( window.confirm("Are you sure you want to delete property type: " + name + "?") ) {
      await deleteList({
        variables: { id: Number(id) },
      });
      setCrud(!crud);
    } 
  }

  const listCreate =  async (title:string, description:string, parentId?:number) => {
    if( title==="" || title.length <= 3 ) {
      window.alert("Enter a valid name");
      return;
    }
    if( description==="" || description.length <= 0 ) {
      window.alert("Enter a valid description");
      return;
    }

    await createList({variables:{title: title, description: description, parentId}});
    setCrud(!crud);
  }

  const listUpdate = async (id:number, field:string, text:string) =>{
    console.log("listUpdate: ", id, " field: ", field, " text: ", text)
    if( text==="" || text.length <= 3 ) {
      window.alert("Must enter a valid value for " + field);
      return;
    }

    await updateList({ variables:{id: id, [field]: text } });
    setCrud(!crud);
  }

  /*
    Stuff needed to create a new list item.
    Use this for items that have a parent.
  */
  const NewListItem = (props:{parentId:number}) => {
    const [title, setTitle] = useState<string>("");
    const doEnter = (id:string,str:string) => {
      window.document.getElementById("newDesc")?.focus();
      if( id==="newDesc" ) {
        listCreate(title, str, props.parentId);
      }
    }
    return (
      <tr>
        <td>
          <Editable id="newList" type="Text" onEnter={doEnter} handleChange={(id,str)=>setTitle(str)} text={title} placeHolder="New Item"/>
        </td>
        <td><Editable id="newDesc" type="TextArea" onEnter={doEnter}/></td>
        <td/>
      </tr>
    )
  }

  const ListItems = ( props:{hidden:boolean, parentId:number, items:IList[]} ) => {
    if(props.hidden) return <></>;
    return (
      <>
        { props.items.map(child => <ShowListItem key={child.id} item={child} />) }
        <NewListItem parentId={props.parentId} />
      </>
    )
  }

  const ListMain = (props:{item: IList}) => {
   const [hidden, setHidden] = useState<boolean>(false);
    const [over,setOver]=useState<boolean>(false);
    return (
      <>
        <tr onClick={()=>setHidden(!hidden)}>
          <td style={{display:'flex', flexDirection: 'row', verticalAlign:'top'}}>
            {hidden ? <FaAngleRight /> : <FaAngleDown/>}
            <Editable id="title" type="Text" onEnter={(id,str)=>{listUpdate(props.item.id, "title", str)}} text={props.item.title} bold/>
          </td>
          <td>
            <Editable id="description" type="TextArea" onEnter={(id,str)=>{listUpdate(props.item.id, "description", str)}} text={props.item.description} />
          </td>
          <td className={over?"":"tdDynamic"}><Button onClick={()=>handleDelete(props.item.id,props.item.title)} variant="danger" type="button"> delete </Button></td>
        </tr>
        <ListItems hidden={hidden} parentId={props.item.id} items={props.item.children||[]} /> 
      </>
    )
  }

  /*const mouseOver = (event:React.MouseEvent<HTMLElement>, id:number) => {
    let el = window.document.getElementById(id.toString());
    console.log("Mouse over ", id, el, event)
    return (
      <div>
        <h1>Hi Izzy!</h1>
      </div>
    )
  }*/

  //split(/\n/).map(str => <p>{str}</p>)
  const ShowListItem = (props:{item: IList}) => {
    const [over,setOver]=useState<boolean>(false);

      return (
        <tr key={props.item.id} onMouseOver={e=>setOver(true)} onMouseOut={e=>setOver(false)}>
          <td>
            <Editable id="ttile" type="text" onEnter={(id,str)=>{console.log("ID: ", id); listUpdate(props.item.id, "title", str)}} text={props.item.title} />
          </td>
          <td id={props.item.id.toString()}>
            <Editable id="description" type="TextArea" onEnter={(id,str)=>{console.log("ID: ", id); listUpdate(props.item.id, "description", str)}} text={props.item.description} />
          </td>
          <td className={over?"":"tdDynamic"}><Button onClick={()=>handleDelete(props.item.id,props.item.title)} variant="danger" type="button"> delete </Button></td>
        </tr>
      )

  }

  return (
    <React.Fragment>
    <h2>Dictionary</h2>
      <Table borderless size="sm" hover>
        <tbody>
          {
            data && (data.lists).map(parentList => {
                return <ListMain key={parentList.id} item={parentList} />
            }) 
          }
        </tbody> 
      </Table>
      <br/>
      <Table size="sm" borderless>
        <tbody>
          <tr>
            <td><TextField id="title" placeHolder="New List" autoFocus={true} handleChange={handleChange} value={list.title}/></td>
            <td><TextField id="description" placeHolder="description" handleChange={handleChange} value={list.description} /></td>
            <td><Button onClick={()=>listCreate(list.title,list.description)} variant="success" type="button">New List</Button></td>
          </tr>
        </tbody>
      </Table>
    </React.Fragment>
  );
}

export default List;