import React, { useState, useEffect } from "react";
import { IPropertyTypes } from "../../types/Property";
import { TextField} from "../Form";
import { Button, Table } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_PROPERTY_TYPE, NEW_PROPERTY_TYPE, PROPERTY_TYPES_QUERY } from "../../platform/graphql";

type newPropertyType = {
  name: string,
  description: string,
}

export const PropertyTypes = () => {
  const [crud, setCrud] = useState<boolean>(false);
  const [newType, setNewType] = useState<newPropertyType>({name:"",description: ""});

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[crud])

  const [deletePropertyType] = useMutation(DELETE_PROPERTY_TYPE);
  const [createPropertyType] = useMutation(NEW_PROPERTY_TYPE, {
    variables: {
      name: newType.name,
      description: newType.description,
    }
  });

  const {loading, error, data, refetch} = useQuery<IPropertyTypes>(PROPERTY_TYPES_QUERY);
  if (loading) return <h2>Loading...</h2>
  if (error) return <pre>{error.message}</pre>


  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    let str = event.currentTarget.value.replace(/^\s+/,'') ;
    str = str.replace(/\s+$/,'');
    switch(event.currentTarget.name) {
      case "name":
        setNewType({
          ...newType,
          name: str
        } as newPropertyType)
        break;
      case "description":
        setNewType({
          ...newType,
          description: str
        } as newPropertyType)
        break;
    };
  }

  const handleClick: React.MouseEventHandler<HTMLTableCellElement> =  (event) => {
    console.log("Click event: ", event, event.currentTarget.id)
  }

  const handleDelete = async (id: number, name: string) => {
    if( window.confirm("Are you sure you want to delete property type: " + name + "?") ) {
      console.log("Handle mutation")
      await deletePropertyType({
        variables: { id: Number(id) },
      });
      setCrud(!crud);
    } 
  }

  const handleNew =  async () => {
    if( newType.name==="" || newType.name.length <= 0 ) {
      window.alert("Enter a valid name");
      return;
    }
    if( newType.description==="" && !window.confirm("Are you sure you want to enter a blank description?") ) {
      return;
    }

    await createPropertyType();
    setNewType({name: "", description: ""});
    setCrud(!crud);
  }

  return (
    <React.Fragment>
    <h2>Property Types</h2>
    {/* <form> */}
      <div className="form-group">
        <Table bordered size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {
              data && (data.propertyTypes).map(pt => {
                return (
                <tr key={pt.id}>
                  <td>{pt.name}</td>
                  <td id={pt.id.toString()} onClick={handleClick}>{pt.description}</td>
                  <td><Button onClick={()=>handleDelete(pt.id, pt.name)} variant="danger" type="button"> delete </Button></td>
                </tr>)
              }) 
            }
            <tr>
              <td><TextField id="name" placeHolder="Name"  handleChange={handleChange} value={newType.name}/></td>
              <td><TextField id="description" placeHolder="Description" handleChange={handleChange} value={newType.description}/></td>
              <td><Button onClick={()=>handleNew()} variant="success" type="submit"> Create </Button></td>
            </tr>
          </tbody> 
        </Table>
      </div>
    {/* </form> */}
    </React.Fragment>
  );
}
