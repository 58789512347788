import { Table } from "react-bootstrap";
import { currencyFormat } from "./Formats";
import { IMortgage } from "./Loan";

const AmortizationTable = (props: {mortgage?:IMortgage}) => {
    if( typeof(props.mortgage)==="undefined" ) return <></>;

    let principle = props.mortgage.principle;
    let intpaid = 0;
    let prinpaid = 0;
    let amort = [];
    for(var i=0; i<props.mortgage.term; i++) {
      let intAmt = (props.mortgage.interest * principle) ;
      let principleAmt = (props.mortgage.monthly - intAmt);
      let num = i+1;
      intpaid += intAmt;
      prinpaid += principleAmt
      principle -= principleAmt

      amort.push(<tr>
        <td>{num}</td>
        <td>{currencyFormat(props.mortgage.monthly)}</td>
        <td>{currencyFormat(principleAmt)}</td>
        <td>{currencyFormat(intAmt)}</td>
        <td>{currencyFormat(prinpaid)}</td>
        <td>{currencyFormat(intpaid)}</td>
        <td>{currencyFormat(principle)}</td>
      </tr>)
    }

    return (
      <Table bordered size="sm">
        <thead>
          <tr>
            <td>Payment<br/>Number</td>
            <td>Payment</td>
            <td>To Principle</td>
            <td>To Interest</td>
            <td>Cumulative<br/>Principle</td>
            <td>Cumulative<br/>Interest</td>
            <td>Balance</td>
          </tr>
        </thead>
        <tbody>
          {amort}
        </tbody> 
      </Table>
    )
}

export default AmortizationTable;

/*interface IMortgage {
  principle: number;
  term: number;
  monthly: number;
  divisorPow: number;
  interest: number;
  totalPayout: number;
  totalInterest: number;
}*/