import { currencyColored } from './Formats';
import { Card, Table } from 'react-bootstrap';
import { IUnit } from '../types/Unit';

interface ILoanParams {
  height?: number;
  unit: IUnit;
}

export const UnitCard = (props: ILoanParams) => {
  const {unit, height} = props;

  return (
    <Card style={{ width: '18rem', height: height || '410px' }}>
    <Card.Body>
      <Card.Title>Unit {unit?.number}</Card.Title>
      <Table borderless hover size="sm">
        <tbody>
        <tr>
          <td>Number:</td>
          <td>{unit?.number}</td>
        </tr>
        <tr>
          <td>Type:</td>
          <td>{unit?.type}</td>
        </tr>
        <tr>
          <td>Beds:</td>
          <td>{unit?.beds}</td>
        </tr>
        <tr>
          <td>Baths:</td>
          <td>{unit?.beds}</td>
        </tr>
        <tr>
          <td>Rent:</td>
          <td>{currencyColored(unit?.rent)}</td>
        </tr>
        <tr><td/><td/></tr>
        </tbody>
      </Table>
    </Card.Body>
    </Card>
  );
}